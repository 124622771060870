<script lang="ts">
	import axios from 'axios';
	import Cookies from 'js-cookie';
	import { onMount } from 'svelte';

	import { COOKIE_KEYS } from '~/utils/cookie';
	import Input from './Input.svelte';
	import { Label } from 'shadcn/label';
	import { Button } from 'shadcn/button';
	import { PUBLIC_ENV_CONFIG } from '~/utils/env-config';

	let redirectUrl = '';

	let value = '';
	let error = '';
	let isLoading = false;

	const onClick = async () => {
		if (!value) return;

		error = '';
		isLoading = true;

		const fd = new FormData();

		fd.set('password', value);

		try {
			await axios.post('/api/check-pwd', fd, {
				baseURL: PUBLIC_ENV_CONFIG.APP_URL,
			});

			Cookies.set(COOKIE_KEYS.pwdp, window.btoa(value));

			window.location.replace(redirectUrl);
		} catch (err) {
			error = 'Incorrect password';
		}

		isLoading = false;
	};

	onMount(() => {
		const pwdp = Cookies.get(COOKIE_KEYS.pwdp);

		if (pwdp) {
			const sp = new URLSearchParams(window.location.search);

			redirectUrl = sp.get('cb') || '';

			if (!redirectUrl) return;

			window.location.replace(redirectUrl);
		}
	});
</script>

<div class="flex items-center justify-center min-w-screen min-h-screen">
	<div class="max-w-[320px] w-full p-s1.5 bg-dark rounded-s2 flex flex-col gap-s1">
		<Label>Password</Label>
		<Input type="password" bind:value disabled={isLoading} />
		<span class="text-xs text-danger">{error}</span>
		<Button disabled={isLoading} on:click={onClick}>Confirm</Button>
	</div>
</div>
